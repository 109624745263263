@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700");

.bounty_template_full_screen {
  width: calc(100vw - 35px); 
}
  
  html,
  body {
    margin: 0;
    height: 100%;
    font-family: "Poppins";
  }
  div,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span {
    font-family: "Poppins";
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
  }
  button {
    outline: none;
  }
  
  .Progress_Status {
    position: absolute;
    top: 50%;
    bottom: 0%;
    left: 25%;
    right: 25%;
    width: 50%;
    border: 1px solid #fff;
    border-radius: 2px;
    background-color: transparent;
    height: 20px;
  }
  
  .myprogressBar {
    width: 1%;
    height: 20px;
    background-color: #44e2d0;
    text-align: center;
    line-height: 20px;
    color: #fff;
    font-size: 16px;
  }
  
  .double_bounce1,
  .double_bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #44e2d0;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
  
    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out;
  }
  
  .double_bounce2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  
  @-webkit-keyframes sk-bounce {
    0%,
    100% {
      -webkit-transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1);
    }
  }
  
  @keyframes sk-bounce {
    0%,
    100% {
      transform: scale(0);
      -webkit-transform: scale(0);
    }
    50% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }
  
  .spinner {
    display: block;
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 50%;
    position: absolute;
    display: none;
  }
  .loading_title {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 50%;
    position: absolute;
    width: 300px;
    color: #FFFFFF;
    text-align: center;
    margin-top: 50px;
    font-size: 12px;
    font-family: 'Poppins';
  }
  .viewer_not_found {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 50%;
    width: 50%;
    text-align: center;
    display: none;
  }
  .viewer_not_found_text {
    font-size: 16px;
  }
  
  .thewiewerwindow {
    background-color: #FFFFFF;
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  .rendercanvas {
    grid-column: 1;
    grid-row: 2;
    width: 100% !important;
    height: 100% !important;
    display: inline-block;
    background-size: cover;
    background-color: #464646;
    outline: none;
    overflow: hidden;
  }
  .grid_bg {
    background-image: url('../../images/bg.svg');
  }
.cursor_grab {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
}
.cursor_move {
  cursor: move;
}
.cursor_zoomIn {
  cursor: zoom-in;
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
}
.cursor_zoomOut {
  cursor: zoom-out;
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
}
  .nav_container {
    grid-row: 1;
    z-index: 1;
    display: grid;
    height: max-content;
    
    padding: 3px 12px 3px 12px;
    //grid-template-columns: 1fr auto auto auto 1fr;
    border-top: solid #C8C8CA 1px;
    border-bottom: solid #C8C8CA 1px;
    background-color: #FFFFFF;
    align-items: center;
    justify-content: space-between;
  }
  .nav_controls {
    display: flex;
    flex-direction: row;
    
    align-items: center;
    height: 100%;
    grid-column: 1;
    justify-content: flex-start;
    cursor: pointer;
  }
  .nav_controls_icon {
    position: relative;
    margin: auto 0;
    font-size: 12px;
    line-height: 18px;
    height: 100%;
    border-left:  2px solid #FFFFFF;
    display: grid;
    justify-items: center;
    padding: 0px 12px;
    align-items: center;
    &:hover {
      .tooltip_content {
        transition-delay:1s;
        visibility: visible;
      }
    }
  }
  .total_steps{
    display: flex;
    color: #2E2E2F;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    flex-direction: column;
    align-items: center;
    margin: auto 0;
  }

  .nav_views {
    display: flex;
    flex-direction: row;
   
    height: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 100%;
    grid-column: 2;
  }
  .nav_views_icon {
    position: relative;
    margin: auto 0;
    font-size: 12px;
    line-height: 18px;

    display: grid;
    justify-items: center;
    border-left:  2px solid #FFFFFF;
    padding: 0px 12px;
    height: 100%;
    &:hover {
      .tooltip_content {
        transition-delay:1s;
        visibility: visible;
      }
    }
  }
  .nav_views_icon_active {
    border-bottom:  5px solid #00C7B1;
    font-weight: 700;
    svg {
      .prime_color_svg {
        fill: #2E2E2F;
        stroke: #2E2E2F;
      }
    }
  }
  .nva_options {
    display: flex;
    grid-column: 4;
    flex-direction: row;
  
    height: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

.nva_options_icon {
  position: relative;
  margin: auto 0;
  font-size: 12px;
  line-height: 18px;
  height: 100%;
  display: grid;
  border-left:  2px solid #FFFFFF;
  justify-items: center;
  padding: 0px 12px;
  &:hover {
    .tooltip_content {
      transition-delay:1s;
      visibility: visible;
    }
  }
}


.tools_icon {
  position: relative;
  margin: auto 0;
  font-size: 12px;
  line-height: 18px;
  display: grid;
  padding: 4px 12px;
  justify-items: start
}

.tools_arrow {
  padding-top: 12px;
  padding-left: 2px;
}

.dropdown {
  &:hover {
    .dropdown_content {
      visibility: visible;
    }
  }
}

.dropdown_content {
  visibility: hidden;
  position: absolute;
  width: max-content;
  z-index: 1;
  top: 72px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  height: auto;
  padding: 8px 0px;
}

.dropdown_content:after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  left: 50%;
  bottom: 90%;
  z-index: -1;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: #FFFFFF;
}

.tooltip_content {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  top: 76px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  height: auto;
  padding: 8px;
  width: max-content;
  height: max-content;
}

.tooltip_content:after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  left: 50%;
  bottom: 75%;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: #FFFFFF;
}

.tooltip_content_options {
  bottom: 20px;
}

.rotate_active {
  border-bottom:  5px solid #00C7B1;
  svg {
    .prime_color_svg {
      fill: #2E2E2F;

    }
  }
}
.pan_active {
  border-bottom:  5px solid #00C7B1;
  svg {
    .prime_color_svg {
      fill: #2E2E2F;

    }
  }
}
.zoomIn_active {
  border-bottom:  5px solid #00C7B1;
  svg {
    .prime_color_svg {
      stroke: #2E2E2F;

    }
  }
}
.zoomOut_active {
  border-bottom:  5px solid #00C7B1;
  svg {
    .prime_color_svg {
      stroke: #2E2E2F;
    }
  }
}

.options_active {
  border-bottom:  5px solid #00C7B1;
  font-weight: 700;
  svg {
    .prime_color_svg {

      stroke: #2E2E2F;
    }
  }
}
.ipr_active {
  border-bottom:  5px solid #00C7B1;
  font-weight: 700;
  svg {
    .prime_color_svg {

      fill: #2E2E2F;
    }
  }
}

#steps_container {
  width: 98%;
  display: flex;
  margin: auto;
  height: 8vh;
  overflow-x: hidden;
  overflow-y: hidden;
  justify-self: center;
  z-index: -1;
  position: relative;
  background-color: #FFFFFF;
}

.steps_content {
  width: 100%;
  display: grid;
  position: relative;
  height: 8vh;
  cursor:pointer;
  grid-template-rows: repeat( 3 , auto);
  grid-template-columns: repeat( auto-fit , minmax(5px, 1fr));
}

.steps {
  row-gap: 8px;
  display: grid;
  align-self: center;
  height: 8vh;
  overflow: hidden;
  z-index: -1;
  width: 100%;
  grid-template-columns: 50px 1fr;
}

.step {
  background-color: #2E2E2F;
  height: 5px;
  width: 100%;
  cursor: pointer;
}


.emptyStep {
  background-color: transparent !important;
  height: 5px;
  width: 100%;
  cursor: default;
}

.passiveAligners {
  background-color: #cccccc !important;
  height: 5px;
  width: 100%;
  cursor: pointer;
}

.firstStep {
  background-color: #2E2E2F;
  height: 5px;
  cursor: pointer;
  width: 100%;

}

.overCorrectionStepsNumber {
  height: 5px;
width: 100%;
cursor: pointer;
  background-color: #D90429 !important;
}

.labels_content {
  position: relative;
  width: 100%;
  height: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Poppins';
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.label_upper {
  width: 100%;
  color: #2E2E2F;
  font-size: 14px;
  z-index: 8;
  font-weight: 500;
  text-align: center;
  align-self: center;
  min-width: 25px;
  cursor: default;
  font-family: 'Poppins';
}
.current_step {
  position: absolute;
  z-index:12;
  top: 8px;
  font-size: 14px;
  line-height: 10.2px;
  font-weight: 600;
  color: #000000;
  border-radius: 4px;
  padding: 4px;
  width: 18px;
  height: 10px;
  text-align: center;
}
.tooltip_upper {
  position: absolute;
  z-index:12;
  top: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 10.2px;
  background: #000000;
  color: #FFFFFF;
  border-radius: 4px;
  padding: 4px;
  width: 22px;
  height: 12px;
  text-align: center;
}
.tooltip_upper:after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  left: 50%;
  bottom: -25%;
  z-index: -2;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: #000000;
}

.first_label_upper {
  opacity: 0;  
  width: 100%;
  font-size: 14px;
  text-align: center;
  align-self: center;
  min-width: 25px;
  z-index: 8;
  cursor: default;
  font-family: 'Poppins';
}


.play_pause_container {
  display: flex;
  align-items: center;
  margin-top: 8px;
  cursor: pointer;
  .play_puase_prime_color_svg {
    fill: #44e2d0;
  }
  div:active {
      svg {
        .prime_color_svg {
          fill: #292D32;
        }
      }
  }
}
.play_pause_container_big_steps {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: fixed;
  bottom: 55px;
  left: 45%;
  background-color: #ffff;
  border-radius: 5px 5px 0px 0px;
  padding: 8px 8px 4px 8px;
  height: 40px;
  .play_puase_prime_color_svg {
    fill: #44e2d0;
  }
  div:active {
      svg {
        .prime_color_svg {
          fill: #44e2d0;
        }
      }
  }
}


.steps_controls {
  padding: 0px 4px;
}
.active_step {
background-color: #E4E4E4;
margin: auto 0;
.step {
  width: 80%;
  margin: 0 auto;
}
.emptyStep {
  width: 80%;
  margin: 0 auto;

}
.overCorrectionStepsNumber {
  width: 80%;
  margin: 0 auto;
}
.label_upper {
  visibility: hidden;
}
}
.step_div {
  display: grid;
  flex-direction: row;
  align-items: center;
  height: 8vh;
  align-content: space-evenly;
}
.nav_icon_full_screen {
  display: grid;
  grid-column: 5;
  justify-items: flex-end;
  cursor: pointer;
  margin-inline-start: auto;
  width: 50px;
}
.divider {
  content: "";
  width: 2px;
  grid-column: 3;
  height: 34px;
  background-color: #C8C8CA;
  display: grid;
  margin: 0px 40px;
}
.tools_div {
  svg {
    transform:rotate(270deg);
  }
  display: flex;
}
.screen_divider {
  content: "";
  border: 2px solid rgba(255, 255, 255, 0.2);
  transform: rotate(90deg);
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0%;
}

.initial_plan {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 85%;
  text-align: center;
  color: #FFFFFF;
  opacity: 0.6;
  position: absolute;
  top: 80px;
  left: 22%;
}
.final_result {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 85%;
  text-align: center;
  color: #FFFFFF;
  opacity: 0.6;
  position: absolute;
  top: 80px;
  right: 22%;
}
.canvas_container {
  display: contents;
  position: absolute;
}
.close_split {
  position: absolute;
  top: 80px;
  right: 2%;
  cursor: pointer;
}